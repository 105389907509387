.svg-black {
    fill: black;
}

.svg-black:hover {
    fill: white;
}

.svg-primary {
    fill: $yellow;
}

.svg-primary:hover {
    fill: black;
}

.svg-light {
    fill: #fff;
}

.svg-light:hover {
    fill: black;
}
