:root {
    --background_colour: #141300;
    --menu_background_colour: #0e0d00;
    --show_background_colour: #1e1d13;
    --show_border_colour: #2f2f2f;
    --font_highlight: #fff203;
    --player_buttons_colour: #fff203;
    --player_ui_colour: #ffffff;
    --player_played: #fff203;
    --player_buffered: #fff203;
    --player_thumb: #fff203;
    --button_highlight_colour: #009AD9;
    --button_highlight_hover_colour: #00B5FF;
    --loading_primary_colour: transparent;
    --loading_secondary_colour: transparent;
    --primary_text_colour: #ffffff;
    --loading_background_colour: #000000;
    --thumb_hover_border_colour: #FFFFFF;
    --modal_background_colour: #000000E6;
}
