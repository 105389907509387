.comments-row {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.comments-avatar {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.comments-main {
    white-space: pre-wrap;
    position: relative;
    width: calc(100% - 56px);
}

.emoji-padding,
.emoji-padding:focus {
    padding-right: 90px;
}

.comments-submit {
    margin-top: 8px;
    font-size: 14px;
    width: 100%;
}

.comments-reply-row {
    width: 100%;
    margin-left: 42px;
}

.comments-action-row {
    width: 100%;
    margin-left: 42px;

    .btn-link {
        color: rgb(155, 155, 155);
        padding-left: 0;
        padding-right: 10px;
    }

    .btn.inline {
        padding: 0 10px 0 0px;
    }
}

.chat-report {
    visibility: hidden;
}

.read-row:hover .chat-report {
    visibility: visible;
}

.emoji-mart {
    z-index: 500;
}
