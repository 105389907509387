.background-type-1 {
    background-image: url("https://cdn.watchcorridor.com/assets/bg-color.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-position: top center;
}

.background-type-2 {
    background-image: url("https://cdn.watchcorridor.com/assets/bg-color.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-position: top center;
}

.background-type-3 {
    background-image: url("https://cdn.watchcorridor.com/assets/bg-color.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-position: top center;
}

.bg-yellow-black {
    background-color: $yellow-black;
}

.bg-white,
.background-white {
    background-color: white;
}

.config-background-1 {
    background-image: var(--background_1);
}

.config-background-2 {
    background-image: var(--background_2);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.config-background-3 {
    background-image: var(--background_3);
    background-repeat: repeat;
    background-size: 450px;
}
