a.yellow:link,
a.yellow:visited {
    color: var(--font_highlight);
}

a.yellow:hover,
a.yellow:active {
    color: #fff;

    &.hover-black {
        color: #000;
    }
}

a.yellow.reason:hover,
a.yellow.reason:active {
    color: #000;
}

a.blue:link,
a.blue:visited {
    color: $blue;
}

a.blue:hover,
a.blue:active {
    color: $blue;
}

.btn-link {
    color: var(--primary_text_colour);
}
