.thumb-watched {
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 3;
    text-align: center;
    width: 20px;
    height: 20px;

    &.large {
        width: 30px;
        height: 30px;
    }
}
