.show-overlay:hover {
    opacity: 1;
}

.show-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.show-button {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}