@import 'src/styles/variables';

.account-grid {
    column-count: 1;
    width: calc(100% - 30px);
    margin-bottom: 15px;
    position: relative;

    @media screen and (min-width: 1000px) {
        column-count: 2;
    }

    @media screen and (min-width: 1500px) {
        column-count: 3;
    }

    .account-grid-item {
        width: 100%;
        background: var(--account_card_colour);
        padding: 20px;
        box-sizing: border-box;
        margin: 0 15px 15px;
        break-inside: avoid;
        border-radius: 8px;


        .account-grid-item-title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 20px;
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
        }

        .account-grid-item-body {
            @media (max-width: $bs-lg) {
                font-size: 22px;
            }

            .account-grid-item-body-divider {
                border-bottom: 1px solid white;
                margin: 20px 0;
            }

            .account-grid-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 5px;
                flex-wrap: wrap;
                text-overflow: ellipsis;
                @media (max-width: $bs-lg) {
                    padding: 5px 0;
                }

                div {
                    overflow: hidden;
                    max-width: 100%;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.form-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: $bs-lg) {
        font-size: 22px;
    }
}

.account-link {
    text-decoration: none;
    color: var(--account_link_colour);

    @media (max-width: $bs-lg) {
        font-size: 22px;
    }

    &:hover {
        text-decoration: none;
        color: var(--account_link_hover_colour);
    }
}
