@import "variables";

$offset: 120px;

.carousel-caption {
    bottom: $page-y-offset - $offset - 20px;
    max-width: 100%;
    left: 0;
    padding-left: 15px;
    text-align: left;
    margin: 0;
    transform: none;
    color: var(--primary_text_colour);

    img {
        height: 100px;
    }

    p {
        font-size: 0.8rem;
    }
}

.carousel-indicators {
    bottom: $page-y-offset - $offset - 60px;

    li {
        width: 10%;
        height: 2px;
        background-color: var(--hero_nav_unselected_colour);
        opacity: var(--hero_nav_unselected_opacity);

        &.active {
            background-color: var(--hero_nav_colour);
        }

        @media (min-width: $bs-lg) {
            height: 4px;
        }
    }
}

.round-indicators {
    .carousel-indicators {
        li {
            width: 7px;
            height: 7px;
            background-color: var(--hero_nav_unselected_colour);
            opacity: var(--hero_nav_unselected_opacity);
            border-radius: 50%;
            margin: 0 7px;

            &.active {
                background-color: var(--hero_nav_colour);
            }

            @media (min-width: $bs-lg) {
                height: 10px;
                width: 10px;
            }
        }
    }
}

.shift-up {
    margin-top: -($page-y-offset - $offset);
}

.carousel-buttons {
    display: none;
    margin-bottom: 1rem;

    button {
        padding: 5px 20px;
    }

    .play-btn {
        margin-right: 25px;
        font-weight: 700;
    }

    .watchlist-btn {
        cursor: pointer;
        color: var(--primary_text_colour);
        letter-spacing: 0.2em;

        &:hover {
            text-decoration: none;
            border-bottom: 2px solid var(--font_highlight);
            padding-bottom: 1px;
        }
    }

    @media (min-width: $bs-lg) {
        display: block;
    }
}

.gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20%;
    background: linear-gradient(180deg, transparent 0%, var(--background_colour) 100%);
    z-index: 0;

    @media (max-width: $bs-lg) {
        height: 50%;
    }
}

// sm
@media (min-width: $bs-sm) {
    .carousel-caption {
        padding-left: $body-text-left-padding;

        p {
            font-size: 1rem;
        }
    }

    .carousel-buttons {
        button {
            padding: 10px 40px;
        }
    }
}

// md
@media (min-width: $bs-md) {
    $offset: 120px;
    .carousel-caption {
        bottom: $page-y-offset + 20px - $offset;
        max-width: 80%;
    }

    .carousel-indicators {
        bottom: $page-y-offset - $offset - 10px;
    }

    .shift-up {
        margin-top: -($page-y-offset - $offset);
    }

    .carousel-caption {
        img {
            height: 130px;
        }
    }
}

// lg
@media (min-width: $bs-lg) {
    $offset: 120px;
    .carousel-caption {
        bottom: $page-y-offset - $offset;
        max-width: 75%;

        p {
            font-size: 1.5rem;
        }
    }

    .carousel-indicators {
        bottom: $page-y-offset - $offset - 10px;
    }

    .shift-up {
        margin-top: -($page-y-offset - $offset);
    }

    .carousel-caption {
        img {
            height: 130px;
        }
    }
}

// xl
@media (min-width: $bs-xl) {
    $offset: 80px;
    .carousel-caption {
        bottom: $page-y-offset + 30px - $offset;
    }

    .carousel-indicators {
        bottom: $page-y-offset - $offset;
    }

    .shift-up {
        margin-top: -($page-y-offset - $offset);
    }

    .carousel-caption {
        img {
            height: 150px;
        }
    }
}

// xxl
@media (min-width: $bs-xxl) {
    $offset: 40px;
    .carousel-caption {
        bottom: $page-y-offset + 70px - $offset;
    }

    .carousel-indicators {
        bottom: $page-y-offset - $offset;
    }

    .shift-up {
        margin-top: -($page-y-offset - $offset);
    }

    .carousel-caption {
        img {
            height: 150px;
        }
    }
}
