@import "variables";

.big-h1 {
    font-size: 3.6rem;
}

.mocap-bg {
    background-image: var(--background_3);
    background-repeat: repeat;
}

.mocap-bg-grad {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 85%, rgba(31, 31, 31, 1) 100%),
    var(--background_3);
    background-repeat: repeat;
}

.latest-video-description {
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-align: justify;
}

.latest-video-channel {
    text-transform: uppercase;
    color: white;
    font-size: 2rem;
}

.latest-video-title {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 2.4rem;
}

.video-col-right {
    position: absolute;
    transform: translate(0%, -50%);
    top: 30%;
    left: 0;
    width: 100%;
    margin: 0;
    padding-left: 15px;
}

.video-col-right-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding-left: 15px;
}

.video-col-left {
    padding-left: 40px;
}

.ar-169 {
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    width: 100%;
    background-color: black;
}

.hr-bottom {
    padding-bottom:  1rem;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom:  1rem;

    @media (max-width: $bs-lg) {
        margin-bottom:  0.5rem;
        padding-bottom:  0.5rem;
    }

    &.hide-hr-mobile {
        @media (max-width: $bs-lg) {
            border-bottom: none;
            padding-bottom: 5px;
        }

    }
}

.hr-top {
    padding-top:  1rem;
    border-top: 1px solid rgba(255,255,255,0.2);
    margin-top:  1rem;

    @media (max-width: $bs-lg) {
        margin-top:  0.5rem;
        padding-top:  0.5rem;
    }

    &.hide-hr-mobile {
        @media (max-width: $bs-lg) {
            border-top: none;
            padding-top: 5px;
        }
    }
}

@media (min-width: 1450px) and (max-width: 1850px) {
    .video-col-right {
        top: 20%;
    }

    .mocap-bg {
        background-size: 450px;
    }

    .mocap-bg-grad {
        background-size: 450px;
    }
}

@media (max-width: 1450px) {
    .latest-video-description {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }

    .latest-video-channel {
        font-size: 1.4rem;
    }

    .latest-video-title {
        font-size: 1.6rem;
    }

    .video-col-right {
        top: 40px;
    }

    .big-h1 {
        font-size: 3rem;
    }

    .mocap-bg {
        background-size: 400px;
    }

    .mocap-bg-grad {
        background-size: 400px;
    }
}

@media (max-width: 1100px) {
    .latest-video-description {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }

    .latest-video-channel {
        font-size: 1.3rem;
    }

    .latest-video-title {
        font-size: 1.2rem;
    }

    .video-col-right {
        padding-right: 40px;
    }

    .big-h1 {
        font-size: 2.6rem;
    }

    .mocap-bg {
        background-size: 300px;
    }

    .mocap-bg-grad {
        background-size: 300px;
    }
}

@media (max-width: 990px) {
    .video-col-right {
        position: relative;
        transform: none;
        top: 0;
        padding-right: 40px;
        padding-left: 40px;
        margin-top: 20px;
    }

    .video-col-left {
        margin-right: 20px;
    }

    .video-col-right-bottom {
        position: relative;
        width: 100%;
        padding-left: 40px;
    }

    .mocap-bg {
        background-size: 250px;
    }

    .mocap-bg-grad {
        background-size: 250px;
    }
}
