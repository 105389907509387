@import "variables";

@media (max-width: 485px) {
    h1 {
        font-size: 2rem;
    }
}

.text-crew {
    color: var(--font_highlight);
}

.text-black {
    color: black;
}

.text-grey {
    color: #c4c4c4;
}

a.a-router {
    text-decoration: none !important;
}

.title-spacing {
    letter-spacing: 0.1rem;
}

.uppercase {
    text-transform: uppercase;
}

input[type="text"],
input[type="text"]:focus,
input[type="number"],
input[type="number"]:focus {

    &:not(.uikit-input) {
        border: 1px solid #444;
        background: rgba(255, 255, 255, 0.02);
        color: var(--primary_text_colour);
    }
}

input[type="text"]::placeholder,
input[type="number"]::placeholder {
    &:not(.uikit-input) {
        color: rgba(255, 255, 255, 0.3);
        font-style: italic;
    }
}

textarea,
textarea:focus {
    width: 100%;
    display: block;
    border: 1px solid #444;
    background: rgba(255, 255, 255, 0.02);
    color: var(--primary_text_colour);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

textarea::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-style: italic;
}

textarea.hide-resize {
    resize: none;
}

.text-gray {
    color: #7f7f7f;
}

.no-wrap {
    white-space: nowrap !important;
}

.font-weight-bold {
    font-weight: 550;
    @media (max-width: $bs-lg) {
        font-weight: var(--font_weight_bold) !important;
    }
}

.text-footer {
    color: var(--primary_text_colour);
}
