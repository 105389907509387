.tt {
    position: relative;
    display: inline-block;
}

.tt .tttext {
    visibility: hidden;
    width: 130px;
    background-color: rgb(70, 70, 70);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -65px;
}

.tt .tttext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(70, 70, 70) transparent transparent transparent;
}

.tt:hover .tttext {
    visibility: visible;
}
