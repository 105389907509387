.proto-modal-background {
    backdrop-filter: blur(6px);
    background-color: var(--modal_background_colour);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50000;
}

.proto-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.proto-modal-big {
    width: 30%;
}

@media (max-width: 2100px) {
    .proto-modal-big {
        width: 35%;
    }
}


@media (max-width: 1900px) {
    .proto-modal-big {
        width: 40%;
    }
}

@media (max-width: 1700px) {
    .proto-modal-big {
        width: 45%;
    }
}

@media (max-width: 1300px) {
    .proto-modal-big {
        width: 55%;
    }
}

@media (max-width: 1000px) {
    .proto-modal-big {
        width: 65%;
    }
}

@media (max-width: 700px) {
    .proto-modal-big {
        width: 80%;
    }
}

@media (max-width: 500px) {
    .proto-modal-big {
        width: 95%;
    }
}
