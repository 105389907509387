@import "src/styles/variables";

video:focus {
    outline: none;
}

.video-js .vjs-big-play-button {
    border: 0 solid var(--font_highlight);
    background-color: rgba(var(--font_highlight_rgb), 0.1);
    transition: transform 0.2s;
    transform: scale(1.5);
}

.video-js:hover .vjs-big-play-button {
    background-color: rgba(var(--font_highlight_rgb), 0.1) !important;
    border: 0 solid var(--font_highlight);
}

.video-js {
    max-height: calc(100vh - $nav-height - $footer-height) !important;

    &.vjs-16-9, .vjs-16-9:not(.vjs-audio-only-mode) {
        padding: 0 !important;
        aspect-ratio: 16/9;
        max-height: calc(100vh - $nav-height - $footer-height);
        height: 100% !important;
    }

    video {
        max-height: calc(100vh - $nav-height - $footer-height) !important;
    }
}

.vjs-error-display {
    display: none;
}

.video-page-container {
    max-width: 1244px + 30px;
    margin: 0 auto;
    padding: 0 15px;

    @media (min-width: 1304px) {
        padding: 0;
    }
}
