@import "styles/_variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "styles/_bootstrap_ext";
@import "styles/_channels";
@import "styles/_comments";
@import "styles/_backgrounds";
@import "styles/_switch";
@import "styles/_video";
@import "styles/greenlight";
@import "styles/_show";
@import "styles/_menu";
@import "styles/_fonts";
@import "styles/_project";
@import "styles/_push-notifications";
@import "styles/_radio";
@import "styles/_video-display";
@import "styles/_scale";
@import "styles/_shaka";
@import "styles/_button";
@import "styles/_card";
@import "styles/_nav-bar";
@import "styles/_modals";
@import "styles/_form-control";
@import "styles/_svg";
@import "styles/_h";
@import "styles/_tooltip";
@import "styles/_text";
@import "styles/_subscription";
@import "styles/_ul";
@import "styles/_a";
@import "styles/_seek-preview";
@import "styles/max-height";
@import "styles/height";
@import "styles/carousel";
@import "styles/legals";
@import "styles/layout";
@import "styles/input";
@import "styles/checkbox";
@import "styles/checkouts";
@import "styles/theme-defaults";
@import "styles/thumb";
@import "styles/account";
@import "styles/subtitles";

.font-highlight {
    color: var(--font_highlight);
}

.recaptcha-docs {
    font-size: 0.75em;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.font-weight-bold {
    font-weight: var(font_weight_bold) !important;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis !important;
}

.country-blocked h3 {
    font-weight: 700 !important;
}

html,
body {
    background: var(--background_colour);
    font-family: var(--font_family);
    margin: 0;
    height: 100%;
    color: var(--primary_text_colour);
}

.form-control:disabled,
.form-control[readonly] {
    background-color: rgba(255, 255, 255, 0.02);
}

.watch_progress_colour {
    .progress-bar {
        background-color: var(--watch_progress_colour) !important;
    }
}

.word-wrap {
    word-wrap: break-word;
}

.w-48 {
    width: 48%;
}

.shift-up-small {
    margin-top: -50px;
}

.shift-up-big {
    margin-top: -150px;
}

/* Centered text */
.hero-overlay {
    position: absolute;
    bottom: $page-y-offset;
    z-index: 2;
}

.beta {
    font-style: italic;
    color: var(--button_highlight_colour);
}

.mocap {
    background-image: var(--background_1);
    background-repeat: repeat-x;
}

.mocap-grad {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(#141300, 1) 100%), var(--background_1);
    background-repeat: repeat-x;
}

.mocap-shifted {
    background-image: var(--background_1);
    background-repeat: repeat-x;
    background-position: 0px 100px;
}

.pointer {
    cursor: pointer;
}

.theatre {
    height: 32px;
}

.report {
    padding: 7.5px 0;
    height: 32px;
}

.theatre-container {
    max-height: 80vh;
    overflow: hidden;
}

#videoUI {
    max-height: 80vh;
    overflow: hidden;
    width: 100%;
}

.theatre-placeholder {
    #videoUI {
        border-radius: 0;
        overflow: hidden;
    }
}

.mh-450-image {
    object-fit: cover;
    height: 450px;
    max-height: 100%;
    max-width: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: black;
}

.nav-pills a.nav-link.active:hover,
.nav-pills a.nav-link.active:not([href]):not([tabindex]):hover {
    color: black !important;
}

.nav-pills .nav-link {
    background-color: rgb(46, 46, 46);
}

.mh-450 {
    height: 450px;
    max-height: 100%;
    max-width: 100%;
}

.mh-500 {
    max-height: 500px;
}

.mh-500-scroll {
    max-height: 500px;
    overflow-y: auto;
}

.thumb-channel-img {
    width: 30% !important;
    display: block;
}

.progress {
    background-color: black !important;
    overflow: hidden;
}

.page-width {
    width: 100%;
}

.page-padding {
    padding-left: 10px;

    @media (min-width: 600px) {
        padding-left: 80px;
    }
}

.angled-spacer {
    height: 125px;
    background-color: white;

    spacer-path {
        fill: var(--background_colour);
    }
}

[hidden] {
    display: none !important;
}

.image-container {
    position: relative;
}

.image-container .text {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    @media screen and (max-width: 600px) {
        top: 10px;
        left: 10px;
        transform: none;
    }
}

/* Colours:
Accent Blue: 00bcda
    Blue: #009ada
    Yellow: #fff203
*/

.apple-logo {
    margin-top: -4px;
}

.emoji {
    height: 24px;
    margin-left: 3px;
    margin-right: 3px;
}

.down-arrow {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70px;
}

.yellow-text {
    color: var(--font_highlight);
}

.green-text {
    color: rgb(0, 160, 0);
}

.red-text {
    color: red;
}

.scroll-colour::-webkit-scrollbar {
    width: 6px;
}

.scroll-colour::-webkit-scrollbar-thumb {
    //border-radius: 6px;
    background-color: var(--font_highlight);
}

.scroll-colour::-webkit-scrollbar-track {
    //border-radius: 6px;
    background-color: rgb(88, 88, 88);
}

/* Bootstrap style override */

.btn-primary-yw {
    color: #000;
    background-color: var(--font_highlight);
    border-color: white;
}

.btn-primary-yw:not(:disabled):not(.disabled).active,
.btn-primary-yw:not(:disabled):not(.disabled):active,
.show > .btn-primary-yw.dropdown-toggle {
    color: #000;
    background-color: #c4ba00;
    border-color: #c4ba00;
}

.btn-primary-yw.focus,
.btn-primary-yw:focus {
    box-shadow: 0 0 0 0.2rem var(--button_highlight_colour) !important;
}

.btn-primary-yw:hover {
    color: #000;
    background-color: #c4ba00;
    border-color: #c4ba00;
}

.btn-outline-primary-yw {
    color: white;
    background-color: transparent;
    background-image: none;
    border-color: white;
}

.btn-outline-primary-yw:hover {
    color: #000;
    background-color: var(--font_highlight);
    border-color: var(--font_highlight);
}

.btn-outline-primary-yw:not(:disabled):not(.disabled).active,
.btn-outline-primary-yw:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary-yw.dropdown-toggle {
    color: #000;
    background-color: #c4ba00;
    border-color: #c4ba00;
}

.btn-outline-primary-yw.focus,
.btn-outline-primary-yw:focus {
    box-shadow: 0 0 0 0.2rem var(--button_highlight_colour) !important;
}

.w-20 {
    width: 20% !important;
}

.close {
    color: var(--primary_text_colour);
}

.modal-content {
    border-radius: 0px;
}

.modal-header {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 8px;
}

.modal-footer {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.metadata-html p {
    margin-bottom: 0px;
}

.metadata-html a:link,
.metadata-html a:visited,
.metadata-html a:hover,
.metadata-html a:active {
    color: var(--font_highlight);
}

.metadata-html a.disabled {
    color: #beb500;
}

.modal-dark {
    background-color: rgb(30, 30, 30) !important;
}

.modal-xl {
    max-width: 75%;
}

.modal-no-border {
    border: none;
}

.chat-badge {
    height: 24px;
}

.chat-badge-large {
    height: 32px;
}

.avatar {
    border-radius: 50%;
    background-color: #000;
}

.click-div:hover,
.clickable {
    cursor: pointer;
}

.avatar-sidebar {
    width: 30%;
}

.avatar-small {
    width: 40px;
    height: 40px;
}

.avatar-col {
    padding-right: 6px;
}

.avatar-normal {
    width: 100%;
}

.avatar-container {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    overflow: hidden;
    max-width: 170px;
    position: relative;
}

.avatar-big {
    width: 170px;
    height: 170px;
}

.avatar-container .avatar-upload,
.avatar-container .avatar-delete {
    display: none;
}

.avatar-container:hover .avatar-upload {
    display: block;
    background-color: #fff20380;
    height: 33%;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
}

.avatar-container:hover .avatar-delete {
    display: block;
    background-color: #fff20380;
    height: 24px;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    cursor: pointer;
    color: var(--primary_text_colour);
}

.avatar-camera {
    width: 24%;
    display: block;
    top: 18%;
    left: 38%;
    position: absolute;
}

.subscription-list {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.corridor-modal {
    backdrop-filter: blur(6px);
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 500;

    &.email-preferences {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 62px;
        padding-bottom: 6em;
        padding-top: 2em;
    }
}

.corridor-modal-close {
    position: absolute;
    right: 14px;
    top: 10px;
    cursor: pointer;
}

.central-modal-content {
    width: 365px;
    max-width: 100%;
    padding: 20px 35px;
    background-color: rgb(59, 59, 59);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.email-preferences {
        width: 440px;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        max-height: 100%;
        overflow: auto;

        @media (min-height: 800px) {
            width: 365px;
        }
    }
}

/* Website Start */

hr {
    border: 0;
    border-top: 1px solid var(--primary_text_colour);
    opacity: 0.7;
}

.hand {
    cursor: pointer;
}

.modal-dialog {
    margin-top: 70px;
}

.modal-content {
    background-color: rgb(37, 37, 37) !important;
}

.bg-dark {
    background-color: rgb(37, 37, 37) !important;
}

nav,
.company-links {
    text-transform: uppercase;
    text-align: center;
    font-weight: var(--font_weight_bold);
}

.company-links a {
    margin-right: 4px;
    margin-left: 4px;
    font-size: 15px;
    font-weight: bold;
}

.white {
    color: var(--primary_text_colour);
}

a.bluelink:link,
a.bluelink:visited,
a.bluelink:active,
a.bluelink:not([href]):not([tabindex]) {
    color: #00bcda !important;
}

a.bluelink:hover,
.dropDownHeader:hover,
a.bluelink:not([href]):not([tabindex]):hover {
    color: var(--font_highlight);
}

a.bluelink.disabled {
    color: #027586 !important;
}

a:link,
a:visited {
    color: var(--primary_text_colour);
}

a:link.text-black,
a:visited.text-black {
    color: black;
}

a:hover.text-black {
    color: white;
}

a:hover,
a:active {
    color: var(--font_highlight);
}

a.disabled {
    color: #beb500;
}

.invalid-feedback {
    font-size: 100%;
    color: #dc3545;
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    border: 2px solid #dc3545;
}

.error {
    color: #dc3545;
}

.close,
.close:hover,
.close:focus {
    color: white;
}

/* Website End */

/* Feature Banner Start

.metis.tp-leftarrow, .metis.tp-leftarrow:hover {
    background: transparent;
    transform-origin: center center;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.metis.tparrows {
    transform-origin: center center;
}

.metis.tp-rightarrow, .metis.tp-rightarrow:hover {
    background: transparent;
}

/* Feature Banner End */

/* Slider start */

$left-channel-padding: 48px;

.main-channel-header {
    color: var(--primary_text_colour);
    font-weight: 550;
    padding: 3px 0px 3px $left-channel-padding;
    margin: 0px;
    margin-bottom: -20px;

    @media (min-width: $bs-lg) {
        font-weight: var(--font_weight_bold);
    }

}

.slider-container {
    padding-left: $left-channel-padding;
    //margin-bottom: 30px; is-removed
}

.sub-channel-header {
    color: var(--font_highlight);
    font-weight: bold;
    padding: 3px 0px 3px 45px;
    margin: 0px;
}

.full-width {
    padding: 0px;
}

section {
    padding: 20px 0;
}

section .section-title {
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
}

/* Login / Register Start */
.main {
    margin: 0;
}

.main-full {
    min-height: calc(100vh - 64px - 68px);
    padding-top: 30px;
    padding-bottom: 30px;

    .mobile-first & {
        padding: 0;

        @media screen and (min-width: $bs-sm) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}

.main-responsive {
    max-width: 470px;
    margin: 0 auto;
    padding: 4em 2em;
    min-width: 330px;

    &.wide {
        max-width: 100%;

        .d-wide {
            display: none;

            @media screen and (min-width: 750px) {
                display: block;
            }
        }

        .d-slim {
            display: block;

            @media screen and (min-width: 750px) {
                display: none;
            }
        }
    }
}

.entry {
    height: 100%;
    color: white;
}

.wide-content {
    background: rgba($color: #000000, $alpha: 0.5);
    padding: 50px 80px;
}

.account-management {
    padding-left: 60px;
    padding-right: 60px;
}

.entry-content {
    background: var(--menu_background_colour);
    max-width: 650px;
    margin: 5% auto auto auto;
    padding: 10px 25px;

    @media (min-width: $bs-lg) {
        padding: 50px 85px;
    }
}

.entry-content-fw {
    max-width: 100%;
}

.entry-content-lg {
    max-width: 1100px;
}

.entry-content a:link,
.entry-content a:visited,
.entry-content a:hover,
.entry-content a:active {
    color: var(--font_highlight);
}

.entry-content-left {
    float: left;
    width: 40%;
}

/* Login / Register End */

/* Limit Reached */

.limit-entry {
    height: 100%;
    color: white;
    background-image: url("https://cdn.watchcorridor.com/assets/limit-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    background-position: top center;
}

/* Video Start */

#video {
    width: 100%;
}

/* Video End */

/* Search Start */

.thumb-placeholder {
    height: 152px;
    width: 270px;
}

/* Search End */

.form-error-message {
    color: #dc3545;
}

.div-loader2 {
    background: #fff2038c;
    width: 100%;
    height: 100%;
}

.div-loader {
    position: absolute;
    z-index: 1150;
    height: 60px;
    width: 60px;
    left: 50%;
    margin-top: 30px;
    margin-left: -30px;
}

.div-loader,
.div-loader:after {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.div-loader {
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1.1em solid var(--loading_secondary_colour);
    border-right: 1.1em solid var(--loading_secondary_colour);
    border-bottom: 1.1em solid var(--loading_secondary_colour);
    border-left: 1.1em solid var(--loading_primary_colour);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.scale-hover {
    transition: all 0.2s ease-in-out;
}

.scale-hover:hover {
    transform: scale(1.3) !important;
}

/* Owl */
.owl-dots,
.owl-nav {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity 0.2s ease;
    border-radius: 30px;
}

.owl-dots .owl-dot span {
    background: $dark-yellow;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
    background: $yellow;
}

.owl-prev {
    position: absolute;
    top: 50%;
    display: block;
    margin-left: 10px;
}

.disabled {
    display: none !important;
}

.owl-next {
    position: absolute;
    top: 50%;
    display: block;
    right: 10px;
}

.owl-prev i,
.owl-next i {
    transform: scale(1, 6);
    color: #ccc;
}

.arrow-padding-left {
    padding-left: 20px;
}

button:focus {
    outline: 0;
}

.nav-bg {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    padding: 0px 10px;
    width: 45px;
}

/* Dropzone */

.dropzone {
    height: 120px;
    padding: 40px;
}

.dz-message {
    height: 120px;
}

.dz-wrapper,
.dz-message {
    background-color: transparent !important;
}

/* SLIDER */

.owl-stage-outer {
    padding: 25px 0px;
    margin-top: 12px;
}

.slider-item {
    margin: 0 2px;
    overflow: hidden;
}

/* slider sclaing */

.owl-video-slider .owl-stage-outer .owl-stage .owl-item {
    transition: transform 300ms ease 100ms;
}

.owl-video-slider .owl-stage-outer .owl-stage:hover .owl-item {
    transform: translateX(-15%);
}

.owl-video-slider .owl-stage-outer .owl-stage .owl-item:hover ~ .owl-item {
    transform: translateX(15%);
}

.owl-video-slider .owl-stage-outer .owl-stage .owl-item:hover:nth-of-type(1) ~ .owl-item {
    transform: translateX(35%) !important;
}

.owl-video-slider .owl-stage-outer .owl-stage .owl-item:hover {
    transform: scale(1.3) !important;
}

.owl-video-slider .owl-stage-outer .owl-stage .owl-item:hover:last-of-type {
    transform: scale(1.3) translateX(-15%) !important;
}

.owl-video-slider .owl-stage-outer .owl-stage .owl-item:hover:first-of-type {
    transform: scale(1.3) translateX(15%) !important;
}

/* sliding sclae end*/

.owl-nav {
    opacity: 0;
    transition: opacity 300ms ease 100ms;
}

.owl-stage-outer:hover + .owl-nav,
.owl-nav:hover {
    opacity: 1;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    padding: 25px 0px !important;
    height: 100%;
    top: 0;
    margin: 0px;
}

.owl-next {
    right: 0;
}

.slider-arrow-left {
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -21px;
}

.slider-arrow-right {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -21px;
}

.dropDownHeader {
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.slideup,
.slidedown {
    -webkit-transition: opacity 300ms, visibility 300ms, max-height 300ms;
    transition: opacity 300ms, visibility 300ms, max-height 300ms;
}

.slideup {
    opacity: 0;
    visibility: hidden;
    max-height: 0px;
}

.slidedown {
    visibility: visible;
    opacity: 1;
    max-height: 300px;
}

.subitem {
    padding: 2px;
    cursor: pointer;
    border-left: 5px solid #a1abb2;
    background: #000;
    font-size: 0.8rem;
    text-transform: none;
}

.nav-link {
    cursor: pointer;
    font-weight: 550;
    font-size: 1.2rem;
    color: var(--primary_text_colour);
}

.nav-link:hover {
    color: var(--font_highlight);
}

a.nav-link:hover,
a.nav-link:not([href]):not([tabindex]):hover {
    color: var(--font_highlight) !important;
}

.owl-stage {
    min-height: 100px;
}

.btn-group-lg > .btn,
.btn-lg {
    border-radius: 0;
}

@media (min-width: 1600px) {
    .col-xxl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xxl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xxl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xxl-first {
        order: -1;
    }

    .order-xxl-last {
        order: 13;
    }

    .order-xxl-0 {
        order: 0;
    }

    .order-xxl-1 {
        order: 1;
    }

    .order-xxl-2 {
        order: 2;
    }

    .order-xxl-3 {
        order: 3;
    }

    .order-xxl-4 {
        order: 4;
    }

    .order-xxl-5 {
        order: 5;
    }

    .order-xxl-6 {
        order: 6;
    }

    .order-xxl-7 {
        order: 7;
    }

    .order-xxl-8 {
        order: 8;
    }

    .order-xxl-9 {
        order: 9;
    }

    .order-xxl-10 {
        order: 10;
    }

    .order-xxl-11 {
        order: 11;
    }

    .order-xxl-12 {
        order: 12;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66667%;
    }
}

.modal-backdrop.show {
    opacity: 0.95;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.col-centered-left {
    float: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: 0;
}

.col-centered-right {
    float: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: auto;
}

.btn-apple {
    background-color: rgb(182, 182, 182);
    color: #000;
    border-color: rgb(182, 182, 182);
}

.btn-apple:hover,
.btn-apple:focus,
.btn-apple:active,
.btn-apple.active,
.open .dropdown-toggle.btn-default {
    background-color: rgb(128, 128, 128);
    color: #000;
    border-color: rgb(128, 128, 128);
}

.btn-facebook {
    background-color: #3b5998;
    color: #fff;
    border-color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open .dropdown-toggle.btn-default {
    background-color: #2f477a;
    color: #fff;
    border-color: #2f477a;
}

.btn-google {
    background-color: rgb(217, 83, 79);
    color: #fff;
    border-color: rgb(217, 83, 79);
}

.btn-google:hover,
.btn-google:focus,
.btn-google:active,
.btn-google.active,
.open .dropdown-toggle.btn-default {
    background-color: rgb(175, 66, 62);
    color: #fff;
    border-color: rgb(175, 66, 62);
}

.devices-all {
    width: 90%;
    display: block;
}

.entry-content-right {
    background: rgba(0, 0, 0, 0.6);
    width: 90%;
    display: block;
    padding: 1.6rem 2rem !important;
    margin-bottom: 30px;
}

.entry-content-right a:link,
.entry-content-right a:visited,
.entry-content-right a:hover,
.entry-content-right a:active {
    color: var(--font_highlight);
}

.show-slider .owl-stage-outer {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 5px;
    margin-top: 0px;
}

.show-slider {
    margin-bottom: 0px;
}

.mt-40px {
    margin-top: 40px;
}

.slider-container-rp {
    padding-right: 40px;
}

.ar-169 {
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
}

.ar-5-7 {
    aspect-ratio: 5 / 7;
}

.ar-16-9 {
    aspect-ratio: 16 / 9;
}

.ar-25-9 {
    aspect-ratio: 25/9;
}

.ar-4-3 {
    aspect-ratio: 4/3;
}

.hero-container-ar {
    aspect-ratio: 4/3;

    @media screen and (min-width: $bs-lg) {
        aspect-ratio: 25/9;
    }
}


@media (min-width: 992px) {
    .devices-all {
        top: 50%;
    }

    .entry-content-right {
        top: 50%;
    }
}

@media (min-width: 1000px) {
    .entry-content-right {
        padding: 1.8rem 3rem !important;
    }
}

@media (min-width: 1200px) {
    .right-border {
        border-right: rgba(255, 255, 255, 0.75) solid 1px;
        padding-right: 80px;
    }

    .left-border {
        padding-left: 80px;
    }
}

@media (min-width: 1500px) {
    .entry-content-right {
        padding: 2.5rem 4rem !important;
    }
}

@media (max-width: 900px) {

    .avatar-big,
    .avatar-container {
        width: 120px;
        height: 120px;
    }

    .account-management {
        padding-left: 30px;
        padding-right: 30px;
    }

    .main-channel-header {
        padding: 3px 0px 3px 30px;
    }

    .slider-container {
        padding-left: 25px;
    }

    .slider-container-rp {
        padding-right: 25px;
    }

    .wide-content {
        padding: 30px 60px;
    }
}

@media (max-width: 485px) {

    .avatar-big,
    .avatar-container {
        width: 90px;
        height: 90px;
    }

    .account-management {
        padding-left: 15px;
        padding-right: 15px;
    }

    .main-channel-header {
        padding: 3px 0px 3px 15px;
    }

    .slider-container {
        padding-left: 15px;
    }

    .slider-container-rp {
        padding-right: 15px;
    }

    .wide-content {
        padding: 20px 30px;
    }
}

.subscription-row {
    background-color: rgb(27, 27, 27);
    border: lightgrey solid 1px;
    padding: 8px;
    margin-bottom: 4px;
}

.subscription-row h5 {
    margin-top: 0;
    margin-bottom: 2px;
}

.subscription-row p {
    margin: 0px;
}

.rounded {
    border-radius: 1rem !important;
}

.no-select {
    user-select: none;
}

input[type="number"].remove-arrows {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
}

.hidemenus {
    .simple-alert {
        left: 30px;
    }
}

.hide-mobile {
    @media (max-width: 600px) {
        display: none;
    }
}

.flex-auto {
    flex: auto;
}

.inline-checkout-modal input {
    background-color: green !important;
}

.password-input-container {
    position: relative;
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
    /* Add your icon representation here, either as a background-image or font-icon, etc. */
}

.hover-accent:hover {
    color: var(--font_highlight);
}
