/*
.legal-document h1, h2, h3, h4, h5, h6 {
	color: white;
	font-weight: unset !important;
	letter-spacing: unset !important;
	text-transform: unset !important;
}

.legal-document ul > li::before {
	content: none !important;
}

.legal-document ul > li >*:first-child::before{
	padding-right: 10px;
    content: "\2022";
	display: inline-block;
    margin-left: -40px;
    min-width: 40px;
    text-align: right;
    box-sizing: border-box;
}

.legal-document.h2, h3, h4, h5, h6 {
	color: white;
	font-weight: unset !important;
	letter-spacing: unset !important;
	text-transform: unset !important;
	margin-bottom: 18px;
	line-height: 1.65em !important;
}

.legal-document h1 {
	font-size: 30px;
	font-weight: 700 !important;
	color: #fff203;
	letter-spacing: unset !important;
	text-transform: unset !important;
	margin-bottom: 30px;
	margin-top: 30px;
}

.legal-document > h2 {
	font-size: 2.5rem !important;
}

.legal-document > h3 {
	font-size: 2rem !important;
}

.legal-document markdown > p {
    font-size: 18px !important;
}

.legal-document > h4 {
	font-size: 1.5rem !important;
}

.legal-document  > h5 {
	font-size: 1rem !important;
}
*/

.legal-document {
    li::before {
        color: var(--primary_text_colour);
    }
}
