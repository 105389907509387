.form-control:focus {
    border-color: unset;
    box-shadow: unset;
}

.btn:focus,
.btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: unset;
}

.form-control-dark {
    background-clip: unset !important;
    color: white;
    background: rgba(15, 15, 15);
    border: 1px solid rgb(34, 34, 34);
}

.form-control-dark:focus {
    color: white;
    background: rgba(15, 15, 15);
    box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 25%);
}

a.black-link:link,
a.black-link:visited {
    color: #000;
    font-weight: bold;
}

a.white-link:link,
a.white-link:visited {
  color: var(--primary_text_colour);
  font-weight: bold;
}
