.card {
    background-color: $box-grey;
    color: $off-black;
    border-width: 2px;
    border-color: $off-black;
    max-height: 90vh;
    overflow-y: auto;
}

.card-modal {
    background-color: $box-grey;
    color: $off-black;
    border-width: 0px;
    border-radius: 4px;
}

.card-left {
    border-right-width: 0;
    margin-top: 120px;
}

.card-right {
    border-left-width: 0;
    margin-top: 120px;
}

.card-logo {
    width: 50%;
    fill: black;
    padding-bottom: 15px;
    color: black;
}

.card-logo-small {
    width: 100px;
    padding-bottom: 5px;
}

.card-title {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    padding-top: 30px;
}

.card-body {
    text-align: center;
    font-size: 0.9rem;
    padding-top: 0px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 60px;
}

.card-body-slim {
    width: 76%;
    margin-left: 12%;
}

.card hr {
    background-color: $off-black;
    opacity: 0.2;
}


.card::-webkit-scrollbar {
    width: 12px;
}

.card::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
}

.card::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 1000px) {
    .card-body-slim {
        width: 84%;
        margin-left: 8%;
    }
}

@media (max-width: 700px) {
    .card-body-slim {
        width: 90%;
        margin-left: 5%;
    }
}

@media (max-width: 500px) {
    .card-body-slim {
        width: 96%;
        margin-left: 2%;
    }
}
