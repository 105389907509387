.section {
    position: relative;
}

.section-center-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 30vw;

    @media (max-width: 992px) {
        max-width: 95vw;
    }
}

.section-left-text {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0, -50%);
    max-width: 30vw;
}

.section-right-text {
    position: absolute;
    text-align: right;
    top: 50%;
    right: 5%;
    transform: translate(0, -50%);
    max-width: 30vw;
}

.section-left-image {
    position: absolute;
    text-align: right;
    top: 50%;
    right: 5%;
    transform: translate(0, -50%);
    max-width: 50vw;
}

.section-upper-right-text {
    position: absolute;
    text-align: right;
    top: 10%;
    right: 5%;
    transform: translate(0, -50%);
    max-width: 30vw;
}

.play-greenlight {
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
