@import "src/styles/variables";

h1,
h2,
h3 {
    color: var(--primary_text_colour);
    font-weight: var(--font_weight_bold);;
    letter-spacing: 0.2rem;
}

h1 {
    font-size: 3rem;
}

h4 {
    font-weight: var(--font_weight_bold);;
}

h3 {
    font-size: 20px;
    @media (min-width: $bs-lg) {
        font-size: 24px;
    }
}
