@font-face {
    font-family: 'Astro';
    src: url('../assets/fonts/astro/Astro-Space.ttf.eot'), /* IE9 Compatibility */
    url('../assets/fonts/astro/Astro-Space.ttf.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/astro/Astro-Space.ttf.svg') format('svg'); /* Legacy iOS */
}

.astro-font {
    font-family: 'Astro', sans-serif;
}
