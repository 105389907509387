@import "src/styles/variables";

.btn {
    padding: 10px 40px;
    border-radius: 2px;
}

.btn-slim {
    padding: 5px 40px;
}

.btn-spacing {
    letter-spacing: 0.2rem;
}

.btn-outline-light {
    border-color: var(--button_outline_border_light);
    color: var(--button_standard_text);
    background-color: transparent;

    &:disabled {
        opacity: 0.5;
        color: var(--button_standard_text) !important;
        border-color: var(--button_outline_border_light);
    }

    &:hover:not([disabled]) {
        background-color: var(--button_outline_hover_background_light);
    }
}

.btn-outline-primary {
    border-color: var(--button_highlight_colour);
    color: var(--button_highlight_colour);

    &:disabled {
        color: var(--button_highlight_colour);

        &:hover {
            border-color: var(--button_highlight_colour);
            color: var(--button_highlight_colour);
        }
    }
}

.btn-outline-primary:hover:not(:disabled) {
    background-color: var(--button_highlight_hover_colour);
    border-color: var(--button_highlight_hover_colour);
    color: var(--button_primary_colour);
}

.btn-primary {
    color: var(--button_primary_colour);
    background-color: var(--button_highlight_colour);
    border-color: var(--button_highlight_colour);

    &:hover, &:focus, &:active, &:disabled {
        color: var(--button_primary_colour);
        background-color: var(--button_highlight_hover_colour);
        border-color: var(--button_highlight_hover_colour);
    }
}

a:link.btn-primary,
a:visited.btn-primary {
    color: #000;
}

a:link.btn-outline-secondary,
a:visited.btn-outline-secondary {
    color: $yellow-black;
}

a:hover.btn-outline-secondary {
    color: white;
}

.btn-no-outline {
    border-width: 0px;
}

@media (max-width: 991px) {
    .btn-no-outline-lgs {
        border-width: 1px;
    }
}

.btn-link {
    color: var(--button_highlight_colour);
    font-size: 0.9rem;
    font-weight: var(--font_weight_bold);
}

.btn-link:not(:disabled):not(.disabled).active,
.btn-link:not(:disabled):not(.disabled):active,
.show > .btn-link.dropdown-toggle {
    color: var(--button_highlight_colour);
}

.btn-link:hover {
    color: var(--font_highlight);
}

.btn-link.black {
    color: black;
}

.btn-link.black:not(:disabled):not(.disabled).active,
.btn-link.black:not(:disabled):not(.disabled):active,
.show.black > .btn-link.black.dropdown-toggle {
    color: black;
}

.btn-link.white:hover {
    color: white;
}

.btn-link.white {
  color: white;
}

.btn-link.white:not(:disabled):not(.disabled).active,
.btn-link.white:not(:disabled):not(.disabled):active,
.show.white > .btn-link.white.dropdown-toggle {
  color: white;
}

.btn-link.white:hover {
  color: white;
}

.btn-height {
    height: 46px;
}

.btn-link-slim {
    padding: 0;
    font-size: 1rem;
    font-weight: 100;
}

.scaled {
    font-size: 100%;
}

.btn-v2 {
    background-color: rgba(var(--video_page_accent_rgb), 0.2);
    border: none;
    color: var(--primary_text_colour);
    padding: 10px 14px;
    position: relative;
    height: 44px;
    border-radius: 8px;

    @media (max-width: $bs-lg) {
        font-size: 14px;
        height: 35px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        background-color: rgba(var(--video_page_accent_rgb), 0.3);
    }

    &.active {
        background-color: var(--button_highlight_colour);

        &:hover {
            background-color: var(--button_highlight_hover_colour);
        }
    }
}
