$bar-height: 24px;

.background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
}

.content {
    position: fixed;
    bottom: 0;
    right: 0;
    color: #f1f1f1;
    padding: 40px;
    padding-right: 80px;
    z-index: 1;
}

.height-100vh {
    min-height: 100vh;
    z-index: 2;
}

.project-banner-image {
    padding-left: 160px;
    height: 200px;
    width: 100%;
}

.project-play {
    height: 32px;
    margin-top: -18px;
    cursor: pointer;
}

.project-row {
    background-color: #333;
    padding: 50px 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.project-description {
    font-size: 1.3rem;
    line-height: 1.6rem;
    text-align: justify;
}

.project-channel {
    text-transform: uppercase;
    color: white;
    font-size: 2rem;
}

.project-title {
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 2.8rem;
}

.project-button {
    width: 45%;
}

.project-col-right {
    position: absolute;
    transform: translate(0%, -50%);
    top: 50%;
}

.ar-169 {
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    width: 100%;
    background-color: black;
}

.img-container {
    position: relative;
}

.project-progress-bar {
    position: absolute;
    bottom: 20px;
    z-index: 100;
}

.progress-padding {
    height: $bar-height;
}

.project-container {
    position: relative;
    text-align: center;
    color: white;
    width: 100%;
}

.overlay-container {
    position: relative;
}

.project-container .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    transition: 0.5s ease;
    background-color: black;
}

.project-container:hover .overlay {
    opacity: 0;
}

.project-container .overlay-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: 12px solid $gold;
}

.overlay-container {
    transition: all 0.2s ease-in-out;
    z-index: 3000;
}

.overlay-container:hover {
    transform: scale(1.05);
}

.top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 6%;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.complete-bar {
    background-color: $gold;
    height: 24px;
    width: 100%;
}

.producer-point-normal {
    height: 24px;
    margin-left: 5px;
    margin-bottom: 3px;
}

.banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 75%, rgba(31, 31, 31, 1) 100%);
}

@media (max-width: 990px) {
    .video-col-right {
        position: relative;
        transform: none;
        top: 0;
        padding-right: 40px;
        padding-left: 40px;
        margin-top: 20px;
    }

    .video-col-left {
        margin-right: 20px;
    }
}

@media (max-width: 1450px) {
    .project-description {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }

    .project-channel {
        font-size: 1.4rem;
    }

    .project-title {
        font-size: 2rem;
    }

    .video-col-right {
        padding-right: 40px;
    }
}

@media (max-width: 1100px) {
    .project-description {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }

    .project-channel {
        font-size: 1.3rem;
    }

    .project-title {
        font-size: 1.6rem;
    }

    .video-col-right {
        padding-right: 40px;
    }
}
