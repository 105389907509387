// Define the colors
$color-texts: ('transparent','white', 'black', 'red', 'green', 'blue', 'yellow', 'magenta', 'lightblue');
$colors: (
        transparent,
        #ffffff,
        #000000,
        #c80000,
        #00c800,
        #0000c8,
        #eedc00,
        #d60080,
        #009fda
);

// Generate classes for each color
@for $i from 1 through length($colors) {
    $color: nth($colors, $i);
    $color-text: nth($color-texts, $i);

    .no-shadow-#{$color-text} {
        &.settings {
            text-shadow: none !important;
        }
        .shaka-text-container div span {
            text-shadow: none !important;
        }
    }

    .raised-#{$color-text} {
        &.settings {
            text-shadow: 0 -1px 0 $color, 0 1px 0 darken($color, 20%) !important;
        }
        .shaka-text-container div span {
            text-shadow: 0 -1px 0 $color, 0 1px 0 darken($color, 20%) !important;
        }
    }

    .depressed-#{$color-text} {
        &.settings {
            text-shadow: 0 -1px 0 darken($color, 20%), 0 1px 0 $color !important;
        }
        .shaka-text-container div span {
            text-shadow: 0 -1px 0 darken($color, 20%), 0 1px 0 $color !important;
        }
    }

    .uniform-#{$color-text} {
        &.settings {
            letter-spacing: 1px !important;
            text-shadow: -1px 1px 0 $color, 1px -1px 0 $color, 1px 1px 0 $color, -1px -1px 0 $color !important;
        }
        .shaka-text-container div span {
            letter-spacing: 1px !important;
            text-shadow: -1px 1px 0 $color, 1px -1px 0 $color, 1px 1px 0 $color, -1px -1px 0 $color !important;
        }
    }

    .drop-shadow-#{$color-text} {
        &.settings {
            text-shadow: 1px 1px 1px $color !important;
        }
        .shaka-text-container div span {
            text-shadow: 1px 1px 1px $color !important;
        }
    }

    .background-#{$color-text} {
        &.settings {
            @if $color == transparent {
                background-color: $color !important;
            } @else {
                background-color: rgba($color, 0.8) !important;
            }
        }
        .shaka-text-container div span {
            @if $color == transparent {
                background-color: $color !important;
            } @else {
                background-color: rgba($color, 0.8) !important;
            }
        }
    }

    .window-#{$color-text} {
        &.settings {
            background-color: $color !important;
        }
        .shaka-text-container div span {
            background-color: $color !important;
        }
    }

    .font-color-#{$color-text} {
        &.settings {
            color: $color !important;
        }
        .shaka-text-container div span {
            color: $color !important;
        }
    }
}

.typewriter {
    &.settings {
        font-family: Courier New, monospace;
    }
    .shaka-text-container div span {
         font-family: Courier New, monospace;
     }
}

.print {
    &.settings {
        font-family: Georgia, serif;
    }
    .shaka-text-container div span {
        font-family: Georgia, serif;
    }
}

.console {
    &.settings {
        font-family: Lucida Console, Monaco, monospace;
    }
    .shaka-text-container div span {
        font-family: Lucida Console, Monaco, monospace;
    }
}

.block {
    &.settings {
        font-family: unset;
    }
    .shaka-text-container div span {
        font-family: unset;
    }
}

.casual {
    &.settings {
        font-family: Comic Sans MS, MarkerFelt-Thin, Roboto Italic, cursive;
    }
    .shaka-text-container div span {
        font-family: Comic Sans MS, MarkerFelt-Thin, Roboto Italic, cursive;
    }
}

.cursive {
    &.settings {
        font-family: Lucida Handwriting, "Droid Serif Italic", cursive;
    }
    .shaka-text-container div span {
        font-family: Lucida Handwriting, "Droid Serif Italic", cursive;
    }
}

.small-caps {
    &.settings {
        font-family: unset;
        font-variant: small-caps;
    }
    .shaka-text-container div span {
        font-family: unset;
        font-variant: small-caps;
    }
}

.font-size-small {
    &.settings {
        font-size: 1em;
    }
    .shaka-text-container div span {
        font-size: 1em;
    }
}
.font-size-medium {
    &.settings {
        font-size: 1.5em;
    }
    .shaka-text-container div span {
        font-size: 1.5em;
    }
}
.font-size-large {
    &.settings {
        font-size: 2em;
    }
    .shaka-text-container div span {
        font-size: 2em;
    }
}
