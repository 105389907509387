@import "variables";

.shaka-bottom-controls {
    padding-bottom: 1.5%;
}

.shaka-play-button {
    background-color: transparent;
    box-shadow: none;
    transition: transform 0.2s;
    padding: 5%;

    @media (max-width: $bs-lg) {
        padding: 15% !important;
    }
}

.shaka-play-button:hover {
    transform: scale(1.5);

    @media (max-width: $bs-lg) {
        transform: scale(1);
    }
}

.shaka-play-button[icon="pause"] {
    background-image: var(--player_pause);

}

.shaka-play-button[icon="play"] {
    background-color: transparent;
    background-image: var(--player_play);
}

.shaka-spinner-path {
    stroke: var(--loading_primary_colour);
    filter: none;
}

.shaka-container {
    position: relative;
}

.shaka-settings-menu {
    z-index: 3;
}

.shaka-controls-container {
    z-index: 1;
}

.shaka-overlay-button {
    z-index: 2;
    cursor: pointer;
    box-sizing: border-box;
    padding: 7.5%;
    width: 0;
    height: 0;
    margin: 0;
    background-color: transparent;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    position: absolute;
    transform: translate(-50%, -50%);
    box-shadow: none;
    transition: transform 0.2s, opacity 0.2s;
}

.shaka-overlay-button:hover {
    transform: translate(-50%, -50%) scale(1.5);
}

.shaka-overlay-button:focus {
    outline: none;
}

.shaka-skip-backward {
    background-image: var(--player_skip_back);
    top: 50%;
    left: 40%;
    padding: 3%;
    transform: translate(-50%, -50%);

    @media (max-width: $bs-lg) {
        padding: 8%;
        left: 34%;
    }
}

.shaka-skip-forward {
    background-image: var(--player_skip_forward);
    top: 50%;
    left: 60%;
    padding: 3%;
    transform: translate(-50%, -50%);
    @media (max-width: $bs-lg) {
        padding: 8%;
        left: 66%;
    }
}

.shaka-scrim-container {
    background: linear-gradient(to top, #000 0, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 85%, #000 100%);
}

.shaka-skip-close {
    background-image: var(--close);
    top: 12%;
    right: -5%;
    padding: 6%;
}

.shaka-settings-menu {
    max-height: 350px;
}

.shaka-text-container.shaka-text-container {
    padding-bottom: 30px;

    span {
        padding: 5px 10px;
        font-size: 12px;

        @media (min-width: $bs-lg) {
            font-size: 24px;
        }
    }
}
