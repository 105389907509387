.plan-button {
    border-radius: 10px;
    background: var(--plan_button_background);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
    font-size: 20px;
    position: relative;

    &.half {
        width: 48%;
        cursor: pointer;
    }

    :before {
        content: "";
        position: absolute;
        left: -1px;
        right: -1px;
        top: -1px;
        bottom: -1px;
        border: 1px solid #909090;
        border-radius: 10px;
    }

    &.active {
        background: var(--plan_button_background_active);

        :before {
            content: "";
            position: absolute;
            left: -2px;
            right: -2px;
            top: -2px;
            bottom: -2px;
            border: 2px solid var(--font_highlight);
            border-radius: 10px;
        }
    }
}

.pick-sub {
    font-size: 26px;
    font-weight: 400;
}

.checkout-container {
    width: 470px;
    max-width: 100%;
}

.check-mark {
    border: 1px solid var(--font_highlight);
    border-radius: 50%;
    background: transparent;
    width: 25px;
    height: 25px;

    .active & {
        background: var(--font_highlight);
    }
}

.pp-container {
    margin-bottom: 25px;
}

.currency-container {
    position: relative;
    height: 2em;
}
