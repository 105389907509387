@include media-breakpoint-up(xs) {
    .w-xs-100 {
        width: 100% !important;
    }
    .w-xs-75 {
        width: 75% !important;
    }
    .w-xs-50 {
        width: 50% !important;
    }
    .w-xs-40 {
        width: 40% !important;
    }
}

@include media-breakpoint-up(sm) {
    .w-sm-100 {
        width: 100% !important;
    }
    .w-sm-75 {
        width: 75% !important;
    }
    .w-sm-50 {
        width: 50% !important;
    }
    .w-sm-40 {
        width: 40% !important;
    }
}

@include media-breakpoint-up(md) {
    .w-md-100 {
        width: 100% !important;
    }
    .w-md-75 {
        width: 75% !important;
    }
    .w-md-50 {
        width: 50% !important;
    }
    .w-md-40 {
        width: 40% !important;
    }
}

@include media-breakpoint-up(lg) {
    .w-lg-100 {
        width: 100% !important;
    }
    .w-lg-75 {
        width: 75% !important;
    }
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-40 {
        width: 40% !important;
    }
}

@include media-breakpoint-up(xl) {
    .w-xl-100 {
        width: 100% !important;
    }
    .w-xl-75 {
        width: 75% !important;
    }
    .w-xl-50 {
        width: 50% !important;
    }
    .w-xl-40 {
        width: 40% !important;
    }
}


