body {
    padding-top: 68px;

    &.hidemenus {
        padding-top: 0;
    }
}

.navbar {
    padding: 0;
    user-select: none;
}

.navbar-toggler img {
    height: 30px;
}

.nav-right,
.navbar-brand {
    padding: 0.5rem 1rem;
}

.navbar-brand img {
    height: 46px;
}



@media (min-width: 992px) {
    .nav-link-bar {
        height: 68px;
    }
}

.navbar-nav {
    height: 100%;
    display: flex;
}

.smart-scroll {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}

.scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.nav-drop {
    position: fixed;
    top: 72px;
    right: 0;
    z-index: 1030;
    padding: 30px;
}

.collapsing {
    -webkit-transition: height 0.2s ease;
    -o-transition: height 0.2s ease;
    transition: height 0.2s ease;
}

.dropdown-menu {
    min-width: 15rem;
    padding-bottom: 20px;
    text-align: center;
    color: white;
    border: #2c2c2c solid 1px;
}

.dropdown-toggle.no-tick::after {
    display: none;
}

@media (max-width: 1100px) {
    .nav-item {
        padding: 0px 15px;
    }
}
