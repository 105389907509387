.ui-checkbox-container {
    display: flex;
    cursor: pointer;
}

.ui-checkbox {
    margin-top: 5px;
    width: 1em;
    height: 1em;
    border: 2px solid var(--font_highlight);
    border-radius: 50%;
    background: transparent;
    flex-shrink: 0;

    .active & {
        background: var(--font_highlight);
    }
}

.ui-checkbox-label {
    padding-left: 20px;
    user-select: none;
}
