.push-notification {
    position: fixed;
    cursor: pointer;
    z-index: 9999;
    bottom: 20px;
    right: 20px;
    width: 20vw;
    background-color: rgba(0, 0, 0, 0.8);
}

.push-notification img {
    width: 100%;
}

.push-notification .inner {
    padding: 20px;
}
