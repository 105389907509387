$yellow: #fff203;
$yellow-hover: #f3e700;
$dark-yellow: #6a6400; // 30% darker
$blue: #009ada;
$orange: #ffc107;
$rorange: #f05125;
$grey: #d1d1d1;
$darkgrey: #1C1C1B;
$gold: #ffca3a;
$blue-black: #000e1a;
$box-grey: #fafafa;
$dull: #bbb;
$off-black: #000322;
$yellow-black: #0e0d00;

$green-light: #2fe06b;
$green-dark: #27a844;

$btn-border-radius: 0rem;
$border-radius: 0rem;

$bs-sm: 576px;
$bs-md: 768px;
$bs-lg: 992px;
$bs-xl: 1200px;
$bs-xxl: 1600px;

$body-left-padding: 48px;
$body-text-left-padding: $body-left-padding + 8px;

$page-y-offset: 200px;
$nav-height: 68px;
$footer-height: 64px;
