.uikit-input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: thin solid rgb(46, 46, 46);
    margin-top: 1em;
    background-color: var(--background_colour);
    font-style: normal;
    color: var(--primary_text_colour);
}

.uikit-input:focus {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: thin solid var(--button_highlight_colour);
    background-color: var(--background_colour);
    color: var(--primary_text_colour);
}

.uikit-input:-webkit-autofill,
.uikit-input:-webkit-autofill:hover,
.uikit-input:-webkit-autofill:focus,
.uikit-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--background_colour) inset !important;
    -webkit-text-fill-color: var(--primary_text_colour) !important;
    transition: background-color 600000s 0s, color 600000s 0s;
    color: var(--primary_text_colour);
    font-size: 18px !important;
}

.uikit-input::-webkit-input-placeholder {
    color: #888;
    font-size: 18px !important;
}
