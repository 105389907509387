.avatar-producer-container {
    position: relative;
}

.producer-points-avatar {
    position: absolute;
    bottom: 0px;
    right: 49px;
    font-size: 16px;
    height: 24px;
    color: $gold;
    font-weight: bold;
    text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.icon {
    width: 24px;
    height: 24px;
    margin-bottom: 0px;
}

.icon-small {
    width: 18px;
    height: 18px;
    margin-bottom: 0px;
}

.producer-points-avatar .text {
    margin-right: 2px;
    vertical-align: bottom;
}
