ul {
    list-style: none; /* Remove default bullets */
}

ul li::before {
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $yellow; /* Change the color */
    font-weight: var(--font_weight_bold); /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

ul li.nav-item::before {
    content: unset; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: unset; /* Change the color */
    display: none; /* Needed to add space between the bullet and the text */
}
